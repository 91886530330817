<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
        <table-order-map :orderMapId="orderMapId"/>
    </div>
  </div>
</template>

<script>
import tableOrderMap from '@/views/component/Table/tableOrderMap.vue'
export default {
    components: {
        tableOrderMap
    },
    data( ) {
        return {
            orderMap: this.$route.query.order,
            orderMapId: [],
            showPreloader: false
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
    },
    methods: {
        refresh(){
            this.showPreloader = true
            this.$http.get(`orders?filter_id=${this.orderMap}`)
                .then(res => {
                    this.orderMapId = res.data
                    this.$store.commit('pageData/setdataCount', this.orderMapId.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        sendToParent(tableData){
            this.reasonProcessing = tableData
            this.$store.commit('pageData/setdataCount', this.reasonProcessing.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`orders?filter_id=${this.orderMap}`)
                .then(res =>{
                    this.orderMapId = res.data
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>

<style>

</style>